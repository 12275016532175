var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PenToolType } from "../Models/PenToolType";
import { colorStringToHex, hexToRgb } from "../util";
export const DrawOnCanvasRoutine = (data, canvas, canvasContext, cursorCanvas, cursorCanvasContext, laserCanvas, laserCanvasContext, imgCursor, zoomBody, currentTool, imageDataReceived) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (data) {
        canvasContext.beginPath();
        laserCanvasContext.beginPath();
        canvasContext.fillStyle = "rgba(0,0,0,1)";
        canvasContext.lineWidth = data.strokeWidth;
        laserCanvasContext.lineWidth = data.strokeWidth;
        console.log("Pen tool type on drawing", data.penType);
        cursorCanvasContext.canvas.hidden = currentTool != PenToolType.cursor;
        switch (data.penType) {
            case PenToolType.rect: {
                if (data.rect) {
                    const x = data.rect.x;
                    const y = data.rect.y;
                    let stateSaved = false;
                    if (data.isAnnotation && imageDataReceived) {
                        canvasContext.save();
                        stateSaved = true;
                        // Clip the annotation area.
                        // same is done for ellipsis.
                        canvasContext.rect(imageDataReceived.left, imageDataReceived.top, imageDataReceived.width, imageDataReceived.height);
                        canvasContext.clip();
                        // close the curent path. and start new one for the annoation.
                        canvasContext.closePath();
                        canvasContext.beginPath();
                    }
                    if (data.rotate) {
                        // More details on Canvas rotation: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/rotate
                        !stateSaved && canvasContext.save();
                        const originX = x + data.rect.width / 2;
                        const originY = y + data.rect.height / 2;
                        canvasContext.translate(originX, originY);
                        canvasContext.rotate((data.rotate * Math.PI) / 180);
                        canvasContext.translate(-originX, -originY);
                    }
                    if (data.fill) {
                        canvasContext.lineWidth = 0;
                        const rgbColor = hexToRgb(colorStringToHex(data.fill));
                        if (rgbColor) {
                            canvasContext.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${data.fillOpacity})`;
                            canvasContext.fillRect(x, y, (_a = data === null || data === void 0 ? void 0 : data.rect) === null || _a === void 0 ? void 0 : _a.width, (_b = data === null || data === void 0 ? void 0 : data.rect) === null || _b === void 0 ? void 0 : _b.height);
                        }
                    }
                    else {
                        canvasContext.rect(x, y, (_c = data === null || data === void 0 ? void 0 : data.rect) === null || _c === void 0 ? void 0 : _c.width, (_d = data === null || data === void 0 ? void 0 : data.rect) === null || _d === void 0 ? void 0 : _d.height);
                        canvasContext.strokeStyle = data.color;
                        canvasContext.stroke();
                    }
                }
            }
            case PenToolType.circle: {
                if (data.circle) {
                    const x = (_e = data === null || data === void 0 ? void 0 : data.circle) === null || _e === void 0 ? void 0 : _e.cx;
                    const y = (_f = data === null || data === void 0 ? void 0 : data.circle) === null || _f === void 0 ? void 0 : _f.cy;
                    const radiusX = ((_g = data === null || data === void 0 ? void 0 : data.circle) === null || _g === void 0 ? void 0 : _g.r) ? Math.abs(data.circle.r) : 0;
                    const radiusY = ((_h = data === null || data === void 0 ? void 0 : data.circle) === null || _h === void 0 ? void 0 : _h.ry) ? Math.abs(data.circle.ry) : 0;
                    let stateSaved = false;
                    if (data.isAnnotation && imageDataReceived) {
                        canvasContext.save(); // saves the current state of canvas. More details: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/save
                        canvasContext.rect(imageDataReceived.left, imageDataReceived.top, imageDataReceived.width, imageDataReceived.height);
                        canvasContext.clip();
                        canvasContext.closePath();
                        canvasContext.beginPath();
                    }
                    if (data.rotate) {
                        // More details on Canvas rotation: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/rotate
                        !stateSaved && canvasContext.save();
                        const originX = data.circle.cx;
                        const originY = data.circle.cy;
                        canvasContext.translate(originX, originY);
                        canvasContext.rotate((data.rotate * Math.PI) / 180);
                        canvasContext.translate(-originX, -originY);
                    }
                    if (data.fill) {
                        const rgbColor = hexToRgb(colorStringToHex(data.fill));
                        if (rgbColor) {
                            canvasContext.lineWidth = 0;
                            canvasContext.ellipse(x, y, radiusX, radiusY, 0, 0, 2 * Math.PI);
                            canvasContext.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${data.fillOpacity})`;
                            canvasContext.fill();
                        }
                    }
                    else {
                        canvasContext.ellipse(x, y, radiusX, radiusY, 0, 0, 2 * Math.PI);
                        canvasContext.strokeStyle = data.color;
                        canvasContext.stroke();
                    }
                }
            }
            case PenToolType.pen: {
                if (data.points) {
                    data.points.map((p, index) => {
                        const x = p.x;
                        const y = p.y;
                        if (index === 0) {
                            canvasContext.moveTo(x, y);
                        }
                        else {
                            canvasContext.lineTo(x, y);
                        }
                        canvasContext.strokeStyle = data.color;
                        canvasContext.stroke();
                    });
                }
            }
            case PenToolType.laser: {
                if (data.points) {
                    data.points.map((p, index) => {
                        const x = p.x;
                        const y = p.y;
                        if (index === 0) {
                            laserCanvasContext.moveTo(x, y);
                        }
                        else {
                            laserCanvasContext.lineTo(x, y);
                        }
                    });
                }
                if (data.isClearLaser) {
                    setTimeout(() => {
                        laserCanvasContext.clearRect(0, 0, laserCanvas.width, laserCanvas.height);
                    }, 500);
                }
            }
            case PenToolType.zoom: {
                zoomBody.style.transform = `matrix(${(_j = data === null || data === void 0 ? void 0 : data.scaleTransform) === null || _j === void 0 ? void 0 : _j.scale}, 0, 0, ${(_k = data === null || data === void 0 ? void 0 : data.scaleTransform) === null || _k === void 0 ? void 0 : _k.scale}, ${(_l = data === null || data === void 0 ? void 0 : data.scaleTransform) === null || _l === void 0 ? void 0 : _l.x}, ${(_m = data === null || data === void 0 ? void 0 : data.scaleTransform) === null || _m === void 0 ? void 0 : _m.y})`;
            }
            case PenToolType.cursor: {
                const drawCursor = () => __awaiter(void 0, void 0, void 0, function* () {
                    cursorCanvasContext.clearRect(0, 0, cursorCanvas.width, cursorCanvas.height);
                    if (data === null || data === void 0 ? void 0 : data.cursorPoint) {
                        cursorCanvasContext.imageSmoothingEnabled = false;
                        const x = data.cursorPoint.x;
                        const y = data.cursorPoint.y;
                        if (data.cursorPoint.width && data.cursorPoint.height) {
                            cursorCanvasContext.drawImage(imgCursor, x, y, data.cursorPoint.width, data.cursorPoint.height);
                        }
                        else {
                            cursorCanvasContext.drawImage(imgCursor, x, y);
                        }
                        requestAnimationFrame(drawCursor);
                    }
                });
                imgCursor.onload = drawCursor;
                imgCursor.src = "../../images/icon_cursor.png";
            }
            default: {
            }
        }
        laserCanvasContext.strokeStyle = data.color;
        laserCanvasContext.stroke();
        canvasContext.restore();
        canvasContext.closePath();
        laserCanvasContext.closePath();
    }
});
