var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let gSImageRef = null;
export const ImageLoadRoutine = (data, gsImage, splashImage, isSplashEnable) => __awaiter(void 0, void 0, void 0, function* () {
    if (data && typeof data == "object" && typeof data.src == "string" && data.src.length) {
        let tempRef = gSImageRef;
        // display image in "#gsImage"
        if (gSImageRef == null) {
            tempRef = gSImageRef = gsImage;
        }
        if (tempRef == null || gSImageRef == null) {
            yield new Promise(res => setTimeout(res, 500));
        }
        if (gSImageRef != null) {
            gSImageRef.src = data.src;
            if (isSplashEnable) {
                splashImage.style.display = 'block';
            }
            else {
                splashImage.style.display = 'none';
            }
            return;
        }
    }
});
