var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IEventDataType } from "./Models/IEventDataType";
import { PenToolType } from "./Models/PenToolType";
import { DrawOnCanvasRoutine } from "./Routines/DrawOnCanvasRoutine";
import { ImageLoadRoutine } from "./Routines/ImageLoadRoutine";
import { ImageLoadRoutineV2, } from "./Routines/ImageLoadRoutineV2";
import { MediaPlayerLoadRoutine } from "./Routines/MediaPlayerLoadRoutine";
import { PDFLoadRoutine } from "./Routines/PDFLoadRoutine";
import { Config, IConfigProps } from "./config";
import { CUSTOM_CHANNEL_NAME, ENABLE_LOGGING } from "./constants";
import { LogType } from "./enum";
import { CancellationToken } from "./helpers.ts/cancellationToken";
import { addDebugLog } from "./helpers.ts/debugInfoLogger";
/**
 * This class will execute each command from the App in sequence.
 * It will have support for cancellation of some commands, e.g. displayImage. Since two images can't be displayed together, so we can cancel the previous running function.
 */
export class RoutineRunner {
    constructor() {
        this._messageQueue = [];
        this._messageCruncherRunning = false;
        this._currentRunningTask = null;
        this._cancellableTasks = [IEventDataType.LoadImage];
        /*
            cast framework and information related variables
        */
        this._castContext = cast.framework.CastReceiverContext.getInstance();
        this._castPlayerManager = this._castContext.getPlayerManager();
        this._shapes = [];
        this._currentScale = 1;
        this._currentTop = 0;
        this._currentLeft = 0;
        this._senderId = null;
        this._currentTool = PenToolType.cursor;
        /*
            Canvas references
            - There multiple canvases, because canvas don't have any layering system, like HTML do.
            - Once we draw on canvas, we can't go back easily. (We may save canvas information on each update. But will be very memory hogging.)
            - To make the implementation simple, we are using multiple canvases.
        */
        this._canvases = {
            whiteBoardShapesCanvas: document.getElementById("canvas"),
            imageCanvas: document.getElementById("imageCanvas"),
            cursorCanvas: document.getElementById("cursorCanvas"),
            laserCanvas: document.getElementById("laserCanvas"),
            annotationCanvas: document.getElementById("annotationCanvas"),
        };
        this._canvasContext = {
            whiteBoardShapesCanvasContext: this._canvases.whiteBoardShapesCanvas.getContext("2d"),
            imageCanvasContext: this._canvases.imageCanvas.getContext("2d"),
            cursorCanvasContext: this._canvases.cursorCanvas.getContext("2d"),
            laserCanvasContext: this._canvases.laserCanvas.getContext("2d"),
            annotationCanvasContext: this._canvases.annotationCanvas.getContext("2d"),
        };
        /**
         * Other HTML References
         */
        this._htmlElementReferences = {
            splashImage: document.getElementById("splashImage"),
            gsImage: document.getElementById("gsImage"),
            zoomOnElement: document.getElementsByTagName("body")[0],
            canvasContainer: document.getElementById("container"),
            canvasWrap: document.getElementById("wrap"),
            castMediaPlayer: document.getElementById("castMediaPlayer"),
            cursorImage: new Image(3, 3),
            debugLogs: document.getElementById("debugLogs"),
        };
        /**
         * Routines/functions to process incoming messages or do other tasks.
         */
        this._routines = {
            loadImageRoutineV1: ImageLoadRoutine,
            loadImageRoutineV2: ImageLoadRoutineV2,
            drawOnCanvasRoutineV1: DrawOnCanvasRoutine,
            playMediaRoutineV1: MediaPlayerLoadRoutine,
            loadPDFRoutineV1: PDFLoadRoutine,
        };
        this.init = () => {
            this._castPlayerManager.addEventListener(cast.framework.events.EventType.MEDIA_STATUS, (e) => {
                addDebugLog("Castplayer Media Status", e);
            });
            this._castPlayerManager.addEventListener(cast.framework.events.EventType.ERROR, (event) => {
                addDebugLog("Cast Framework error", event.error, LogType.Error);
                addDebugLog("Cast Framework error code", event.detailedErrorCode, LogType.Error);
                addDebugLog("Cast Framework error reason", event.reason, LogType.Error);
            });
            // Stop the casting when the sender is disconnected
            this._castContext.addEventListener(cast.framework.system.EventType.SENDER_DISCONNECTED, () => {
                addDebugLog("Sender disconnected", "", LogType.Error);
                this._castContext.stop();
            });
            // Stop the casting when the network is disconnected
            window.addEventListener("offline", () => {
                addDebugLog("Window Offline Callback", "Window offline event fired", LogType.Error);
                //this._castContext.stop();
            });
            // hide logging if logs are not enabled.
            if (!ENABLE_LOGGING) {
                this._htmlElementReferences.debugLogs.style.display = "none";
            }
        };
        this.addMessage = (message) => {
            var _a, _b;
            this._senderId = message.senderId;
            // remove or cancel any other task, which can' run concurrently, like image loading, pdf load
            if (this._cancellableTasks.some((s) => message.data.type === s)) {
                // cancel if running currently
                if (this._cancellableTasks.some((s) => this._currentRunningTask &&
                    this._currentRunningTask.taskMessage.data.type === s)) {
                    addDebugLog("Add Message", "Cancelled the previous image load");
                    (_b = (_a = this._currentRunningTask) === null || _a === void 0 ? void 0 : _a.cancellation) === null || _b === void 0 ? void 0 : _b.cancel();
                }
                // remove from queue
                const sameTypeOfEventsInQueue = this._messageQueue.map((s, index) => {
                    if (this._cancellableTasks.filter((f) => s.data.type === f)) {
                        return index;
                    }
                });
                sameTypeOfEventsInQueue.forEach((m) => {
                    if (typeof m === "number") {
                        const msg = this._messageQueue.splice(m, 1);
                        addDebugLog("Add Message", `Removed previous image load ${m} ${JSON.stringify(msg)}`);
                    }
                });
            }
            this._messageQueue.push(message);
            if (!this._messageCruncherRunning) {
                addDebugLog("Add Message", "Message cruncher was not running. Starting it.");
                this.messageCruncher();
            }
        };
        this.messageCruncher = () => __awaiter(this, void 0, void 0, function* () {
            if (this._messageQueue.length) {
                this._messageCruncherRunning = true;
                while (this._messageQueue.length) {
                    yield this.processMessage();
                }
                addDebugLog("Message cruncher", "Message cruncher stopped, because message queue is empty");
                this._messageCruncherRunning = false;
            }
        });
        this.processMessage = () => __awaiter(this, void 0, void 0, function* () {
            const message = this._messageQueue.shift();
            if (message) {
                //addDebugLog("Message processor | Processing message:", "");
                const token = this.getCancellationToken(message);
                const task = new Promise((res) => __awaiter(this, void 0, void 0, function* () {
                    yield this.runRoutine(message, token);
                    this._currentRunningTask = null;
                    addDebugLog("Message processor | Processing complete for message", "");
                    res();
                })).catch((c) => {
                    addDebugLog("Message processor | Processing error for message:", message.data);
                    this._currentRunningTask = null;
                });
                this._currentRunningTask = {
                    task,
                    cancellation: token,
                    taskMessage: message,
                };
                yield task;
            }
        });
        this.getCancellationToken = (event) => {
            const eventType = event.data.type;
            if (eventType === IEventDataType.LoadImage) {
                // create cancellation token.
                return CancellationToken.create();
            }
            else {
                return undefined;
            }
        };
        this.runRoutine = (message, cancellationToken) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            const isSplashEnable = message.data.isSplashEnable;
            const { castMediaPlayer, canvasContainer, canvasWrap, gsImage, splashImage, } = this._htmlElementReferences;
            const { whiteBoardShapesCanvas, cursorCanvas, laserCanvas, imageCanvas, annotationCanvas, } = this._canvases;
            const { whiteBoardShapesCanvasContext, annotationCanvasContext, laserCanvasContext, } = this._canvasContext;
            if (message.data) {
                const eventType = message.data.type;
                this.sendMessageToSender({
                    status: "addCustomMessageListenerVideoCasting - " + eventType,
                });
                switch (eventType) {
                    case IEventDataType.Init: {
                        addDebugLog("Routine runner", `init message`);
                        castMediaPlayer.style.display = "none";
                        if (message.data && message.data.data) {
                            const width = document.body.clientWidth;
                            const height = document.body.clientHeight;
                            whiteBoardShapesCanvas.width = width;
                            whiteBoardShapesCanvas.height = height;
                            cursorCanvas.width = width;
                            cursorCanvas.height = height;
                            laserCanvas.width = width;
                            laserCanvas.height = height;
                            imageCanvas.width = width;
                            imageCanvas.height = height;
                            annotationCanvas.width = width;
                            annotationCanvas.height = height;
                            canvasContainer.style.width = `${width}px`;
                            canvasContainer.style.height = `${height}px`;
                            canvasWrap.style.width = `${width}px`;
                            canvasWrap.style.height = `${height}px`;
                            if (message.data.data.imageRoutineVersion) {
                                Config.getInstance().setConfig(IConfigProps.imageLoadRoutineVersion, message.data.data.imageRoutineVersion);
                            }
                        }
                        return;
                    }
                    case IEventDataType.LoadImage: {
                        addDebugLog("Routine runner", `Load image`);
                        castMediaPlayer.style.display = "none";
                        const width = message.data.width;
                        const height = message.data.height;
                        if (Config.getInstance().getConfig(IConfigProps.imageLoadRoutineVersion) === 1) {
                            gsImage.style.width = `${width}px`;
                            gsImage.style.height = `${height}px`;
                            canvasContainer.style.width = `${width}px`;
                            canvasContainer.style.height = `${height}px`;
                            whiteBoardShapesCanvas.width = width;
                            whiteBoardShapesCanvas.height = height;
                            cursorCanvas.width = width;
                            cursorCanvas.height = height;
                            laserCanvas.width = width;
                            laserCanvas.height = height;
                            imageCanvas.style.display = "none";
                            gsImage.style.display = "block";
                            this._routines.loadImageRoutineV1(message.data, gsImage, splashImage, isSplashEnable);
                        }
                        else {
                            gsImage.style.display = "none";
                            imageCanvas.style.display = "block";
                            this._commonFunctions.loadImage(message.data, isSplashEnable, cancellationToken);
                        }
                        // textCast.innerHTML = `shapes: ${window.innerWidth} -- ${window.innerHeight}`;
                        return;
                    }
                    case IEventDataType.DrawOnCanvas: {
                        addDebugLog("Routine runner", `Draw on canvas`);
                        const data = message.data;
                        if (data.penType === PenToolType.circle ||
                            data.penType === PenToolType.rect ||
                            data.penType === PenToolType.pen) {
                            this._shapes.push(data);
                        }
                        this._commonFunctions.draw(data, this._currentTool);
                        return;
                    }
                    case IEventDataType.ClearPaint: {
                        const clearUserDrawing = (_a = message.data) === null || _a === void 0 ? void 0 : _a.data.clearUserDrawing;
                        const clearAnnotations = (_b = message.data) === null || _b === void 0 ? void 0 : _b.data.clearAnnotations;
                        //const clearImage = event.data?.data.clearImage; . Image is cleared when new image is loaded.
                        addDebugLog("Routine runner", `Clear paint. Clear drawings: ${clearUserDrawing} | Clear annotations: ${clearAnnotations}`);
                        if (clearUserDrawing) {
                            laserCanvasContext.clearRect(0, 0, laserCanvas.width, laserCanvas.height);
                            this._shapes = this._shapes.filter((s) => s.isAnnotation);
                        }
                        if (clearAnnotations) {
                            this._shapes = this._shapes.filter((s) => !s.isAnnotation);
                        }
                        if (clearUserDrawing || clearAnnotations) {
                            this._commonFunctions.replaceAndReRenderShapes(this._shapes, this._currentTool);
                        }
                        // reset scaling.
                        this._commonFunctions.updateScale(1, 0, 0);
                        this._routines.playMediaRoutineV1(true, {}, this.sendMessageToSender, this._castPlayerManager);
                        return;
                    }
                    case IEventDataType.Eraser: {
                        addDebugLog("Routine runner", `Eraser`);
                        const data = message.data;
                        if (!data.isAnnotation) {
                            whiteBoardShapesCanvasContext.clearRect(0, 0, whiteBoardShapesCanvas.width, whiteBoardShapesCanvas.height);
                            if (data.isClearLaser) {
                                laserCanvasContext.clearRect(0, 0, laserCanvas.width, laserCanvas.height);
                            }
                            this._shapes.splice(data.indexRemove ? data.indexRemove : 0, 1);
                            this._shapes
                                .filter((f) => !f.isAnnotation)
                                .map((shape) => {
                                this._commonFunctions.draw(shape, this._currentTool);
                            });
                        }
                        else {
                            annotationCanvasContext.clearRect(0, 0, whiteBoardShapesCanvas.width, whiteBoardShapesCanvas.height);
                            if (typeof data.indexRemove === "number" && data.indexRemove > -1) {
                                this._shapes.splice(data.indexRemove, 1);
                                this._shapes
                                    .filter((f) => f.isAnnotation)
                                    .map((shape) => {
                                    this._commonFunctions.draw(shape, this._currentTool, true);
                                });
                            }
                        }
                        return;
                    }
                    case IEventDataType.Video: {
                        addDebugLog("Routine runner", `MediaPlayerLoadRoutine`);
                        const data = message.data;
                        imageCanvas.style.display = "none";
                        gsImage.style.display = "none";
                        this._routines.playMediaRoutineV1(false, data.data, this.sendMessageToSender, this._castPlayerManager);
                        return;
                    }
                    case IEventDataType.LoadPDF: {
                        PDFLoadRoutine(message.data);
                        return;
                    }
                    case IEventDataType.ToggleSplashImage: {
                        const allData = message.data;
                        const isVisible = allData.data.isVisible;
                        addDebugLog("Routine runner", `Toggle splash screen: visible: ${isVisible}`);
                        if (isVisible) {
                            splashImage.style.display = "block";
                        }
                        else {
                            splashImage.style.display = "none";
                        }
                        return;
                    }
                    case IEventDataType.GetResolution: {
                        addDebugLog("Routine runner", `Get resolution`);
                        this.sendMessageToSender({
                            width: document.body.clientWidth,
                            height: document.body.clientHeight,
                            type: IEventDataType.GetResolution,
                        });
                        return;
                    }
                    case IEventDataType.ToggleAnnotations: {
                        addDebugLog("Routine runner", `Toggle annotations`);
                        const data = message.data.data;
                        if (data) {
                            if (data.show) {
                                annotationCanvasContext.clearRect(0, 0, annotationCanvas.width, annotationCanvas.height);
                                const annotations = data.annotations.map((a) => {
                                    a.isShow = true;
                                    return a;
                                });
                                this._shapes = this._shapes.filter((s) => !s.isAnnotation);
                                this._shapes.unshift(...annotations);
                                data.annotations.map((shape) => {
                                    this._commonFunctions.draw(shape, this._currentTool, true);
                                });
                            }
                            else if (data.hide) {
                                this._shapes = this._shapes.map((s) => {
                                    if (s.isAnnotation) {
                                        s.isShow = false;
                                    }
                                    return s;
                                });
                                annotationCanvasContext.clearRect(0, 0, annotationCanvas.width, annotationCanvas.height);
                            }
                        }
                        return;
                    }
                    case IEventDataType.BulkUpdateShapes: {
                        const data = message.data.data;
                        addDebugLog("Routine runner | Bulk shapes update", data);
                        if (Array.isArray(data)) {
                            this._commonFunctions.replaceAndReRenderShapes(data, this._currentTool);
                        }
                        return;
                    }
                    case IEventDataType.ShowHideAnnotation: {
                        addDebugLog("Routine runner", `Show hide annotation`);
                        const data = (_c = message.data) === null || _c === void 0 ? void 0 : _c.data;
                        if (data &&
                            typeof data.showHideState === "boolean" &&
                            data.index > -1) {
                            this._shapes.forEach((s, i) => {
                                if (s.isAnnotation && data.index === i) {
                                    s.isShow = data.showHideState;
                                }
                                return s;
                            });
                            this._commonFunctions.replaceAndReRenderShapes(this._shapes, this._currentTool);
                        }
                        return;
                    }
                    case IEventDataType.UpdateCanvasDimentions: {
                        addDebugLog("Routine runner", `Update canvas dimentions`);
                        const data = (_d = message.data) === null || _d === void 0 ? void 0 : _d.data;
                        if (data) {
                            const { width, height } = data;
                            whiteBoardShapesCanvas.width = width;
                            whiteBoardShapesCanvas.height = height;
                            cursorCanvas.width = width;
                            cursorCanvas.height = height;
                            laserCanvas.width = width;
                            laserCanvas.height = height;
                            imageCanvas.width = width;
                            imageCanvas.height = height;
                            annotationCanvas.width = width;
                            annotationCanvas.height = height;
                            canvasContainer.style.width = `${width}px`;
                            canvasContainer.style.height = `${height}px`;
                            canvasWrap.style.width = `${width}px`;
                            canvasWrap.style.height = `${height}px`;
                        }
                        return;
                    }
                    case IEventDataType.UpdateScale: {
                        addDebugLog("Routine runner", `Update scale`);
                        const data = (_e = message.data) === null || _e === void 0 ? void 0 : _e.data;
                        if (data && data.scale) {
                            this._commonFunctions.updateScale(data.scale, data.top, data.left);
                        }
                        return;
                    }
                    case IEventDataType.ToolSelected: {
                        const data = (_f = message.data) === null || _f === void 0 ? void 0 : _f.data;
                        addDebugLog("ToolSelected", data);
                        console.log("tool", data);
                        this._currentTool = data;
                        return;
                    }
                    default:
                        return;
                }
            }
        });
        this._commonFunctions = {
            replaceAndReRenderShapes: (newShapes, currentTool) => {
                const { annotationCanvasContext, whiteBoardShapesCanvasContext } = this._canvasContext;
                const { annotationCanvas, whiteBoardShapesCanvas } = this._canvases;
                addDebugLog("ReplaceAndReRenderShapes", newShapes);
                this._shapes = newShapes;
                annotationCanvasContext.clearRect(0, 0, annotationCanvas.width, annotationCanvas.height);
                whiteBoardShapesCanvasContext.clearRect(0, 0, whiteBoardShapesCanvas.width, whiteBoardShapesCanvas.height);
                this._shapes
                    .filter((s) => s.isAnnotation && s.isShow)
                    .map((s) => this._commonFunctions.draw(s, currentTool, true));
                this._shapes
                    .filter((s) => !s.isAnnotation)
                    .map((s) => this._commonFunctions.draw(s, currentTool));
            },
            draw: (data, currentTool, isAnnotation = false) => {
                const { annotationCanvasContext, whiteBoardShapesCanvasContext, cursorCanvasContext, laserCanvasContext, } = this._canvasContext;
                const { annotationCanvas, whiteBoardShapesCanvas, cursorCanvas, laserCanvas, } = this._canvases;
                const { cursorImage, zoomOnElement } = this._htmlElementReferences;
                this._routines.drawOnCanvasRoutineV1(data, isAnnotation ? annotationCanvas : whiteBoardShapesCanvas, isAnnotation ? annotationCanvasContext : whiteBoardShapesCanvasContext, cursorCanvas, cursorCanvasContext, laserCanvas, laserCanvasContext, cursorImage, zoomOnElement, currentTool, this._imageDataReceived);
            },
            loadImage: (data, isSplashEnable, cancellationToken) => __awaiter(this, void 0, void 0, function* () {
                const { gsImage, splashImage } = this._htmlElementReferences;
                const { annotationCanvas } = this._canvases;
                const { imageCanvasContext } = this._canvasContext;
                this._imageDataReceived = {
                    top: data.top,
                    left: data.left,
                    height: data.height,
                    width: data.width,
                };
                gsImage.style.display = "none";
                // Remove clipping
                annotationCanvas.width = annotationCanvas.width + 1;
                annotationCanvas.height = annotationCanvas.height + 1;
                annotationCanvas.width = annotationCanvas.width - 1;
                annotationCanvas.height = annotationCanvas.height - 1;
                const imageElement = this._routines.loadImageRoutineV2(data, imageCanvasContext, splashImage, this._imageBackUp, isSplashEnable, cancellationToken);
                this._imageBackUp = imageElement;
            }),
            updateScale: (scale, top, left) => {
                const { canvasWrap } = this._htmlElementReferences;
                this._currentScale = scale;
                this._currentTop = top;
                this._currentLeft = left;
                const transform = `scale(${scale})`;
                canvasWrap.style.transform = transform;
                canvasWrap.style.top = `${top}px`;
                canvasWrap.style.left = `${left}px`;
            },
        };
        this.sendMessageToSender = (message) => {
            if (this._senderId) {
                this._castContext.sendCustomMessage(CUSTOM_CHANNEL_NAME, this._senderId, message);
            }
        };
    }
}
/**
 * Instance control variables
 */
RoutineRunner._instance = null;
RoutineRunner.getInstance = () => {
    if (RoutineRunner._instance) {
        return RoutineRunner._instance;
    }
    RoutineRunner._instance = new RoutineRunner();
    RoutineRunner._instance.init();
    return RoutineRunner._instance;
};
