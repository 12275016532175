export const ImageLoadRoutineV2 = (data, canvasContext, splashImage, imageBackUp, isSplashEnable, cancellationToken) => {
    if (data &&
        typeof data == "object" &&
        ((typeof data.src == "string" && data.src.length) || // data have source
            (data.reusePreviousImage && imageBackUp)) // or in case of re-use, imageBAckup must be present.
    ) {
        const { src, height, width, left, top } = data;
        canvasContext.clearRect(0, 0, document.body.clientWidth, document.body.clientHeight);
        if (data.reusePreviousImage && imageBackUp) {
            canvasContext.drawImage(imageBackUp, left ? left : 0, top ? top : 0, width, height);
            if (isSplashEnable) {
                splashImage.style.display = "block";
            }
            else {
                splashImage.style.display = "none";
            }
            return imageBackUp;
        }
        else if (src) {
            const image = new Image();
            if (cancellationToken && cancellationToken.isCancelled) {
                return image;
            }
            image.src = src;
            image.onload = () => {
                if (cancellationToken && cancellationToken.isCancelled) {
                    return;
                }
                canvasContext.clearRect(0, 0, document.body.clientWidth, document.body.clientHeight);
                canvasContext.drawImage(image, left ? left : 0, top ? top : 0, width, height);
                if (isSplashEnable) {
                    splashImage.style.display = "block";
                }
                else {
                    splashImage.style.display = "none";
                }
            };
            return image;
        }
    }
};
