var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const PDFLoadRoutine = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const gsPdf = document.getElementById('gsPdf');
    if (data && typeof data == "object" && typeof data.src == "string" && data.src.length) {
        if (gsPdf !== null) {
            gsPdf.src = "http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"; //data.src;
        }
    }
});
