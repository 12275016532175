export const hexToRgb = (hex) => {
    let r = "0";
    let g = "0";
    let b = "0";
    let a = "1";
    if (hex == "")
        hex = "000000";
    if (hex.charAt(0) == "#")
        hex = hex.substring(1, hex.length);
    if (hex.length != 6 && hex.length != 8 && hex.length != 3) {
        return { r, g, b, a };
    }
    if (hex.length == 3) {
        r = hex.substring(0, 1);
        g = hex.substring(1, 2);
        b = hex.substring(2, 3);
        r = r + r;
        g = g + g;
        b = b + b;
    }
    else {
        r = hex.substring(0, 2);
        g = hex.substring(2, 4);
        b = hex.substring(4, 6);
    }
    if (hex.length == 8) {
        a = hex.substring(6, 8);
        a = (parseInt(a, 16) / 255.0).toFixed(2);
    }
    return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16), a };
};
export const colorStringToHex = (color) => {
    switch (color.toLowerCase().trim()) {
        case "red":
            return "#FF0000";
        case "blue":
            return "#0000FF";
        case "orange":
            return "#FFA500";
        case "yellow":
            return "#FFFF00";
        case "green":
            return "#008000";
        case "purple":
            return "#A020F0";
        case "white":
            return "#FFFFFF";
        default:
            return "#000000";
    }
};
