/**
 * Keeps all the global configuration.
 * It would shared across whole project.
 */
export var IConfigProps;
(function (IConfigProps) {
    IConfigProps["imageLoadRoutineVersion"] = "imageLoadRoutineVersion";
})(IConfigProps || (IConfigProps = {}));
export class Config {
    constructor() {
        this.currentConfig = { imageLoadRoutineVersion: 1 };
        this.getConfig = (propertyName) => {
            return this.currentConfig[propertyName];
        };
        this.setConfig = (propertyName, value) => {
            this.currentConfig[propertyName] = value;
        };
    }
}
Config._instance = null;
Config.getInstance = () => {
    if (Config._instance) {
        return Config._instance;
    }
    Config._instance = new Config();
    return Config._instance;
};
