export var IEventDataType;
(function (IEventDataType) {
    IEventDataType[IEventDataType["Init"] = 0] = "Init";
    IEventDataType[IEventDataType["LoadImage"] = 1] = "LoadImage";
    IEventDataType[IEventDataType["DrawOnCanvas"] = 2] = "DrawOnCanvas";
    IEventDataType[IEventDataType["ClearPaint"] = 3] = "ClearPaint";
    IEventDataType[IEventDataType["Eraser"] = 4] = "Eraser";
    IEventDataType[IEventDataType["Video"] = 5] = "Video";
    IEventDataType[IEventDataType["LoadPDF"] = 6] = "LoadPDF";
    IEventDataType[IEventDataType["ToggleSplashImage"] = 7] = "ToggleSplashImage";
    IEventDataType[IEventDataType["GetResolution"] = 8] = "GetResolution";
    IEventDataType[IEventDataType["ToggleAnnotations"] = 9] = "ToggleAnnotations";
    IEventDataType[IEventDataType["BulkUpdateShapes"] = 10] = "BulkUpdateShapes";
    IEventDataType[IEventDataType["ShowHideAnnotation"] = 11] = "ShowHideAnnotation";
    IEventDataType[IEventDataType["UpdateCanvasDimentions"] = 12] = "UpdateCanvasDimentions";
    IEventDataType[IEventDataType["UpdateScale"] = 13] = "UpdateScale";
    IEventDataType[IEventDataType["MediaPlayerLoaded"] = 14] = "MediaPlayerLoaded";
    IEventDataType[IEventDataType["MediaPlayerLoadUnLoadError"] = 15] = "MediaPlayerLoadUnLoadError";
    IEventDataType[IEventDataType["MediaPlayerUnLoaded"] = 16] = "MediaPlayerUnLoaded";
    IEventDataType[IEventDataType["ToolSelected"] = 17] = "ToolSelected";
})(IEventDataType || (IEventDataType = {}));
