export class CancellationToken {
    constructor() {
        this.isCancelled = false;
        this.cancel = () => {
            this.isCancelled = true;
        };
        this.throwIfCancelled = () => {
            if (this.isCancelled) {
                throw "Cancelled";
            }
        };
    }
    static create() {
        return new CancellationToken();
    }
}
