export var PenToolType;
(function (PenToolType) {
    PenToolType["zoom"] = "zoom";
    PenToolType["pen"] = "pen";
    PenToolType["cursor"] = "cursor";
    PenToolType["rect"] = "rect";
    PenToolType["circle"] = "circle";
    PenToolType["laser"] = "laser";
    PenToolType["erase"] = "erase";
})(PenToolType || (PenToolType = {}));
