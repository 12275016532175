var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IEventDataType } from "../Models/IEventDataType";
import { Config, IConfigProps } from "../config";
var MediaMessageType;
(function (MediaMessageType) {
    MediaMessageType[MediaMessageType["Load"] = 1] = "Load";
    MediaMessageType[MediaMessageType["Play"] = 2] = "Play";
    MediaMessageType[MediaMessageType["Pause"] = 3] = "Pause";
    MediaMessageType[MediaMessageType["SEEK"] = 4] = "SEEK";
})(MediaMessageType || (MediaMessageType = {}));
/**
 * Note: While debugging with chrome debugger user will not see video on either or device (Chromecast machine and Debugging machine).
 * The code is just a POC. There is a lot scope for improvement.
 * @param data
 * @param sendMessageToSender
 * @param playerManager
 */
export const MediaPlayerLoadRoutine = (isClear = false, data, sendMessageToSender, playerManager) => __awaiter(void 0, void 0, void 0, function* () {
    const player = document.getElementById("castMediaPlayer");
    const splashImage = document.getElementById("splashImage");
    const otherElements = [
        "imageCanvas",
        "annotationCanvas",
        "canvas",
        "cursorCanvas",
        "laserCanvas",
    ];
    if (Config.getInstance().getConfig(IConfigProps.imageLoadRoutineVersion) === 1) {
        otherElements.push("gsImage");
    }
    // Loads media based on extension type
    const reloadMedia = () => __awaiter(void 0, void 0, void 0, function* () {
        const loadRequest = new cast.framework.messages.LoadRequestData();
        loadRequest.media.contentUrl = data.urlMedia;
        const videoTrack = new cast.framework.messages.Track(2, cast.framework.messages.TrackType.VIDEO);
        videoTrack.trackContentType = "video/mp4";
        loadRequest.media.tracks = [
            new cast.framework.messages.Track(1, cast.framework.messages.TrackType.AUDIO),
            videoTrack,
        ];
        loadRequest.activeTrackIds = [1, 2];
        loadRequest.media.mediaCategory =
            cast.framework.messages.MediaCategory.VIDEO;
        const metaData = new cast.framework.messages.MediaMetadata(cast.framework.messages.MetadataType.MOVIE);
        loadRequest.media.metadata = metaData;
        yield playerManager.load(loadRequest);
    });
    // show cast player.
    console.log("Loading Player", data.loadPlayer, data);
    let status = playerManager.getPlayerState();
    sendMessageToSender({
        status: "addCustomMessageListener - " +
            isClear +
            " - " +
            data.type +
            " - " +
            data.loadPlayer +
            status,
    });
    if (!isClear) {
        if (data.type === MediaMessageType.Load) {
            if (data.loadPlayer) {
                if (player) {
                    console.log("Player was loaded");
                    player.style.display = "block";
                    otherElements.forEach((oid) => {
                        const otherElement = document.getElementById(oid);
                        if (otherElement) {
                            otherElement.style.display = "none";
                        }
                    });
                    yield reloadMedia();
                    if (splashImage) {
                        splashImage.style.display = "none";
                    }
                    sendMessageToSender({
                        type: IEventDataType.MediaPlayerLoaded,
                    });
                }
                else {
                    // send message to app, that load failed.
                    sendMessageToSender({
                        type: IEventDataType.MediaPlayerLoadUnLoadError,
                    });
                    console.log("Sending Error Message");
                }
            }
            else if (data.unloadPlayer) {
                // hide player.
                // Stop should be called from app.
                if (player) {
                    player.style.display = "none";
                }
                otherElements.forEach((oid) => {
                    const otherElement = document.getElementById(oid);
                    if (otherElement) {
                        otherElement.style.display = "block";
                    }
                });
                // send message to app, that player has been removed.
                sendMessageToSender({ type: IEventDataType.MediaPlayerUnLoaded });
            }
        }
        else if (data.type === MediaMessageType.Play) {
            if (playerManager) {
                playerManager.play();
            }
        }
        else if (data.type === MediaMessageType.Pause) {
            if (status === "IDLE") {
                if (playerManager) {
                    playerManager.play();
                    setTimeout(() => {
                        playerManager.pause();
                    }, 1500);
                }
            }
            else {
                if (playerManager) {
                    playerManager.pause();
                }
            }
        }
        else if (data.type === MediaMessageType.SEEK) {
            if (status === "IDLE") {
                yield reloadMedia();
                if (playerManager) {
                    if ((data === null || data === void 0 ? void 0 : data.time) && (data === null || data === void 0 ? void 0 : data.time) !== 0) {
                        playerManager.seek(data === null || data === void 0 ? void 0 : data.time);
                    }
                    playerManager.play();
                }
            }
            else {
                if (playerManager) {
                    if ((data === null || data === void 0 ? void 0 : data.time) && (data === null || data === void 0 ? void 0 : data.time) !== 0) {
                        playerManager.seek(data === null || data === void 0 ? void 0 : data.time);
                    }
                }
            }
        }
    }
    else {
        if (player) {
            if (playerManager) {
                playerManager.stop();
            }
            player.style.display = "none";
        }
        otherElements.forEach((oid) => {
            const otherElement = document.getElementById(oid);
            if (otherElement) {
                otherElement.style.display = "block";
            }
        });
    }
});
