export var EnvType;
(function (EnvType) {
    EnvType[EnvType["Dev"] = 1] = "Dev";
    EnvType[EnvType["Test"] = 2] = "Test";
    EnvType[EnvType["Prod"] = 3] = "Prod";
})(EnvType || (EnvType = {}));
export var LogType;
(function (LogType) {
    LogType[LogType["Error"] = 1] = "Error";
    LogType[LogType["Info"] = 2] = "Info";
})(LogType || (LogType = {}));
