import { CUSTOM_CHANNEL_NAME } from "./constants";
import { addDebugLog } from "./helpers.ts/debugInfoLogger";
import { RoutineRunner } from "./routineRunner";
const context = cast.framework.CastReceiverContext.getInstance();
const defaultCustomChannelTimeout = 30; // session will timeout in specified minutes.
let lastMessageReceivedBefore = 0; // in minutes.
context.addCustomMessageListener(CUSTOM_CHANNEL_NAME, function (customEvent) {
    lastMessageReceivedBefore = 0; // reset the idle disconnect time.
    const event = customEvent;
    try {
        addDebugLog("Channel message", JSON.stringify(customEvent === null || customEvent === void 0 ? void 0 : customEvent.data));
    }
    catch (e) { }
    RoutineRunner.getInstance().addMessage(event);
});
// 	updateScale: (scale: number, top: number, left: number) => {
// 		currentScale = scale;
// 		currentTop = top;
// 		currentLeft = left;
// 		const transform = `scale(${scale})`;
// 		divWrap.style.transform = transform;
// 		divWrap.style.top = `${top}px`;
// 		divWrap.style.left = `${left}px`;
// 	},
// };
// // workaround to keep the session
// window.setInterval(() => {
//   const message = new cast.framework.messages.LoadRequestData();
//   message.media.contentId = "https://example.com/fake.jpg";
//   playerManager
//     .load(message)
//     .then(() => {})
//     .catch((err) => {
//       return;
//     });
// }, 1000 * 60 * 4); // 4 minutes
//Add media player message interceptor.
// playerManager.setMessageInterceptor(cast.framework.messages.MessageType.LOAD, async function(request) {
//   const error = new cast.framework.messages.ErrorData(cast.framework.messages.ErrorType.LOAD_FAILED);
//   try {
//       let metadata = new cast.framework.messages.GenericMediaMetadata();
//       request.media.contentId = 'https://devmediaservice-jpea.streaming.media.azure.net/404abaf0-a3bc-4f6a-af67-ab2589b997bb/LSv1-U1-REP-Fun%20Together.ism/manifest';
//       request.media.contentUrl = 'https://devmediaservice-jpea.streaming.media.azure.net/404abaf0-a3bc-4f6a-af67-ab2589b997bb/LSv1-U1-REP-Fun%20Together.ism/manifest';
//       request.media.metadata = metadata;
//       return request;
//   } catch (errorMessage) {
//       error.reason = cast.framework.messages.ErrorReason.INVALID_REQUEST;
//       return error;
//   }
// });
// Cast options
const options = new cast.framework.CastReceiverOptions();
options.disableIdleTimeout = true;
context.start(options);
// Custom timeout logic
// https://stackoverflow.com/questions/46673152/google-cast-custom-receiver-timing-out
setInterval(timerIncrement, 60 * 1000); // 1 minute
function timerIncrement() {
    lastMessageReceivedBefore = lastMessageReceivedBefore + 1;
    if (lastMessageReceivedBefore > defaultCustomChannelTimeout) {
        context.stop();
    }
}
